import React, { useEffect, useState } from "react";
import handLogo from "./../../assets/images/Waving_Hand.svg";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Background } from "../../common/Background";
import CustomInput from "../../common/CustomInput";
import CustomInputPassword from "../../common/CustomInputPassword";
import { spinnerStartStop } from "../../redux/action/action";
import { signInTotpApi } from "../../services/api";
import ShowQrCode from "./MFA/ShowQrCode";
import OTPInput from "./OTPInput";

const validationSchema = Yup.object({
  email_address: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .max(20, "Password must be less than 20 characters")
    .required("Password is required"),
});
const Login = () => {
  const [rememberMe, setRemeberMe] = useState();
  const [showOtp, setShowOtp] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cred, setCred] = useState(null);

  const authData = useSelector((state) => state.cylenium.auth);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data) => {
    // dispatch(signInAction(data, rememberMe ? true : false));
    dispatch(spinnerStartStop(true));
    signInTotpApi(data)
      .then((res) => {
        setShowOtp(true);
        toast.success(res.message);
        setCred(data);
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally((final) => dispatch(spinnerStartStop(false)));
  };

  useEffect(() => {
    if (authData)
      if (authData?.x_auth_token) {
        navigate(authData?.landing_page);
        // dispatch(getProfileAction(authData.user_id));
      }
  }, [authData]);
  return (
    <>
      <Background>
        {true ? (
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="login-box">
              <div className="l-inn-content">
                {!showOtp ? (
                  <>
                    {" "}
                    <h5 className="mr-b20">
                      Welcome back
                      <img src={handLogo} alt="" title="" />
                    </h5>
                    <h3 className="text-center"> Sign in to your account </h3>
                  </>
                ) : (
                  <h3>Please Enter MFA Code</h3>
                )}{" "}
                {!showOtp ? (
                  <div className="l-form mx-auto mr-t40">
                    <div className="mb-3">
                      <CustomInput
                        label={"Username"}
                        varient="outline"
                        control={control}
                        defaultValue=""
                        name="email_address"
                        error={errors?.email_address}
                        fullWidth
                        disabled={showOtp}
                      />
                    </div>
                    <div className="group-form cus-f-group ">
                      <div className="mb-3">
                        <CustomInputPassword
                          label={"Password"}
                          name="password"
                          varient="outline"
                          control={control}
                          error={errors?.password}
                          fullWidth
                          disabled={showOtp}
                        />
                      </div>
                    </div>

                    <div className="f-r-block">
                      <label htmlFor="l1" className="r-cus-check">
                        <input
                          type="checkbox"
                          name=""
                          id="l1"
                          onChange={(event) =>
                            setRemeberMe(event.target.checked)
                          }
                        />
                        <span className="mr-r10"></span>
                        Remember me
                      </label>
                      <Link to="/forgot-password">Forgot password?</Link>
                    </div>
                    <div className="l-btm-btn mr-t20">
                      <div className="l-btm-btn mr-t20">
                        <button
                          type="Submit"
                          className="blue-btn  h46 w-100"
                          onClick="location.href=''"
                        >
                          Sign in
                        </button>
                        <button
                          type="button"
                          className="blue-btn  h46 w-100 mt-3"
                          onClick={() => navigate("/setup-mfa")}
                        >
                          Setup MFA
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="l-form mx-auto mr-t40">
                    <div className="mb-3">
                      <OTPInput
                        cred={cred}
                        rememberMe={rememberMe ? true : false}
                      />
                    </div>{" "}
                  </div>
                )}
              </div>
            </div>
          </form>
        ) : (
          <ShowQrCode />
        )}
      </Background>
      {/* <Footer /> */}
    </>
  );
};

export default Login;
