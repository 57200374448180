import { ThemeProvider } from "@mui/material/styles";
import { ConfigProvider, Spin } from "antd";
import "antd/dist/reset.css";
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import "./assets/scss/index.scss";
import "./assets/scss/styles.scss";
import Index from "./components/Index";
import ErrorBoundary from "./utils/ErrorBoundary";
import {
  blackThemeAntd,
  blueThemeAntd,
  whiteThemeAntd,
} from "./utils/themes/antdTheme";
import { blueTheme, darkTheme, whiteTheme } from "./utils/themes/theme";
import("./assets/css/common.css");
const AUTO_LOGOUT_TIME = 15 * 60 * 1000; // 15 minutes of inactivity

function App() {
  const spinner = useSelector((state) => state.cylenium.spinner);
  const themes = useSelector((state) => state.cylenium.themes);

  const timeoutId = useRef(null);
  // const navigate = useNavigate();

  // Function to log out the user
  const logout = () => {
    // Clear user session or token
    sessionStorage.clear();
    localStorage.clear();
    // showConfirm();
    // navigate("/");
  };

  // Reset the inactivity timer
  const resetTimer = () => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
    timeoutId.current = setTimeout(logout, AUTO_LOGOUT_TIME);
  };

  // Setup event listeners to track user activity
  useEffect(() => {
    const events = ["mousemove", "keydown", "click", "touchstart"];

    const handleActivity = () => {
      resetTimer();
    };

    // Add event listeners for user activity
    events.forEach((event) => window.addEventListener(event, handleActivity));

    // Initialize the timer on component mount
    resetTimer();

    // Cleanup event listeners and timer on component unmount
    return () => {
      events.forEach((event) =>
        window.removeEventListener(event, handleActivity)
      );
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
    };
  }, []);

  useEffect(() => {
    if (themes == 1) {
      document.body.style.backgroundColor = "#212528";
    } else if (themes == 2) {
      document.body.style.backgroundColor = "#151a2c";
    } else {
      document.body.style.backgroundColor = "#f1f1f1";
    }
  }, [themes]);
  useEffect(() => {
    if (sessionStorage.getItem("sessionEnd"))
      Swal.fire({
        title: "Session expired",
        text: "Please login again to this portal?",
        icon: "warning",
        // showCancelButton: true,
        confirmButtonColor: "#D8EAFF",
        cancelButtonColor: "#d33",
        confirmButtonText: "Logout",
        confirmButtonTextColor: "#0177FB",
      }).then((result) => {
        sessionStorage.clear();
      });
  }, []);

  return (
    <div
      className={
        themes == 1 ? "dark-theme" : themes == 2 ? "blue-theme" : "white-theme"
      }
    >
      <ToastContainer
        position="bottom-right"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <ConfigProvider
        theme={
          themes == 3
            ? whiteThemeAntd
            : themes == 2
            ? blueThemeAntd
            : blackThemeAntd
        }
      >
        <ThemeProvider
          theme={themes == 3 ? whiteTheme : themes == 2 ? blueTheme : darkTheme}
        >
          <Spin size="large" spinning={spinner?.loading}>
            <ErrorBoundary>
              {/* <VoiceAssistantProvider> */}
              <Index />
              {/* </VoiceAssistantProvider> */},
            </ErrorBoundary>
          </Spin>
        </ThemeProvider>
      </ConfigProvider>
    </div>
  );
}

export default App;
