export const SIGN_IN = "SIGN_IN";
export const SIGN_OUT = "SIGN_OUT";
export const SET_SPINNER = "SET_SPINNER";
export const GET_CHAT_LIST = "GET_CHAT_LIST";
export const RULE_SET_ERROR = "RULE_SET_ERROR";
export const GET_DASHBOARD = "GET_DASHBOARD";
export const GET_DEVICE_TYPE_ACTION = "GET_DEVICE_TYPE_ACTION";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const GET_USERS_LIST = "GET_USERS_LIST";
export const GET_DIVICE_USERS_LIST = "GET_DIVICE_USERS_LIST";
export const GET_DEVICES_LIST = "GET_DEVICES_LIST";
export const GET_NOTIFCATION_COUNT = "GET_NOTIFCATION_COUNT";
export const GET_UNSSIGNED_DOMAIN_DEVICE = "GET_UNSSIGNED_DOMAIN_DEVICE";
export const GET_EMP_MODULE = "GET_EMP_MODULE";
export const GET_TRUST_LEVEL = "GET_TRUST_LEVEL";
export const GET_DEVICES_DOMAIN_LIST = "GET_DEVICES_DOMAIN_LIST";
export const GET_DOMAINS_LIST = "GET_DOMAINS_LIST";
export const GET_USER_BY_ID = "GET_USER_BY_ID";
export const GET_DEVICE_DETAILS_BY_ID = "GET_DEVICE_DETAILS_BY_ID";
export const GET_DEVICE_TYPES = "GET_DEVICE_TYPES";
export const GET_DOMAIN_DETAILS_BY_ID = "GET_DOMAIN_DETAILS_BY_ID";
export const GET_DOMAIN_ENDPOINT_BY_ID = "GET_DOMAIN_ENDPOINT_BY_ID";
export const GET_AI_BLOCKED_LIST = "GET_AI_BLOCKED_LIST";
export const GET_DEVICES_FOR_ADD_USER = "GET_DEVICES_FOR_ADD_USER";
export const CHANGE_THEME = "CHANGE_THEME";
export const GET_TRUST_GROUP_LIST = "GET_TRUST_GROUP_LIST";
export const GET_TRUST_GROUP_RULE = "GET_TRUST_GROUP_RULE";
export const GET_TRUST_GROUP_UNLIST = "GET_TRUST_GROUP_UNLIST";
export const GET_TRUST_GROUP_ACTIVITY_LIST = "GET_TRUST_GROUP_ACTIVITY_LIST";
export const AUTHENTICATION_ATTEMPTS = "AUTHENTICATION_ATTEMPTS";
export const DATA_FLOW_CHART = "DATA_FLOW_CHART";
export const GET_TRUST_GROUP_BY_ID = "GET_TRUST_GROUP_BY_ID";
export const GET_ORAGANIZATION_COMPANY = "GET_ORAGANIZATION_COMPANY";
export const GET_ORAGANIZATION_COMPANY_BY_ID =
  "GET_ORAGANIZATION_COMPANY_BY_ID";
export const GET_ORAGANIZATION_COMPANY_USERS =
  "GET_ORAGANIZATION_COMPANY_USERS";
export const GET_PROFILE_BY_ID = "GET_PROFILE_BY_ID";
export const GET_PROFILE = "GET_PROFILE";
export const UPDATE_PROFILE_BY_ID = "UPDATE_PROFILE_BY_ID";
export const GET_ALL_DEVICE_LIST = "GET_ALL_DEVICE_LIST";
export const GET_ALL_DEVICE_LIST_MODAL = "GET_ALL_DEVICE_LIST_MODAL";
export const GET_SETTING = "GET_SETTING";
export const GET_NOTIFICATION_TAGS = "GET_NOTIFICATION_TAGS";
export const GET_THREAT_ALERTS_LIST = "GET_THREAT_ALERTS_LIST";
export const GET_ACCESS_RULES = "GET_ACCESS_RULES";
export const GET_NOTIFICATION_BY_ID = "GET_NOTIFICATION_BY_ID";
export const GET_NOTIFICATION_USERS = "GET_NOTIFICATION_USERS";
export const GET_NOTIFICATION_TEMPLATES = "GET_NOTIFICATION_TEMPLATES";
export const GET_DASHBOARD_GRAPH_LIST = "GET_DASHBOARD_GRAPH_LIST";
export const GET_LIVE_ALERTS = "GET_LIVE_ALERTS";
export const SECURITY_EVENT_GRAPH = "SECURITY_EVENT_GRAPH";
export const GET_IOT_DEVICES_BY_DEVICE_ID = "GET_IOT_DEVICES_BY_DEVICE_ID";
export const GET_RULE_ID = "GET_RULE_ID";
export const GET_POLICY = "GET_POLICY";
export const DELETE_MODEL = "DELETE_MODEL";
export const GET_RULE_SETS_LIST = "GET_RULE_SETS_LIST";
export const GET_DEVICE_SCANNING_LIST = "GET_DEVICE_SCANNING_LIST";
export const GET_CREATE_SCAN_DETAILS = "GET_CREATE_SCAN_DETAILS";
export const GET_SCHEDULE_LIST = "GET_SCHEDULE_LIST";
export const GET_DEVICE_SCAN_LIST = "GET_DEVICE_SCAN_LIST";
export const GET_DEVICE_SCAN_REPORT_LIST = "GET_DEVICE_SCAN_REPORT_LIST";
export const GET_SCAN_DETAILS_BY_SCAN_NAME = "GET_SCAN_DETAILS_BY_SCAN_NAME";
export const GET_OT_TOKEN = "GET_OT_TOKEN";
export const GET_ALERTS_LIST = "GET_ALERTS_LIST";
export const GET_PLAN_LIST = "GET_PLAN_LIST";
export const GET_INVOICE_HISTORY_LIST = "GET_INVOICE_HISTORY_LIST";
export const GET_INVOICE_DETAILS = "GET_INVOICE_DETAILS";
export const GET_REPORT_ALERTS = "GET_REPORT_ALERTS";
export const GET_ANALYTICS_DETAILS = "GET_ANALYTICS_DETAILS";
export const GET_ACTIVE_PLAN = "GET_ACTIVE_PLAN";
export const GET_DEVICE_TYPES_REPORTS = "GET_DEVICE_TYPES_REPORTS";
export const GET_ANALYTICS_DATA = "GET_ANALYTICS_DATA";
export const RISK_ASSESSMENT_GRAPH = "RISK_ASSESSMENT_GRAPH";
export const CONFIRM_DELETE = "CONFIRM_DELETE";
export const REPORT_OPEN = "REPORT_OPEN";
export const HANDLE_SIDEBAR_OPEN_ADMIN = "HANDLE_SIDEBAR_OPEN_ADMIN";
export const GET_CAMERA_STREAM = "GET_CAMERA_STREAM";
